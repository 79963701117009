import React from 'react';
import {useOutlet} from 'reconnect.js';
import CheckoutReviewPage from 'rev.sdk.js/Templates/CheckoutReviewPage';
import renderCustomCartCol from '../../Utils/renderCustomCartCol';
import renderExtraCheckoutSummary from '../../Utils/renderExtraCheckoutSummary';

function CheckoutReview(props) {
  useOutlet('store');

  return (
    <CheckoutReviewPage
      renderCustomComponent={renderCustomCartCol}
      checkoutSummaryProps={{renderExtraCheckoutSummary}}
      {...props}
    />
  );
}

export default CheckoutReview;
